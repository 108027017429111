import styled from 'styled-components';
import PageHeader from 'components/PageHeader/PageHeader';
export const FavoritePageHeader = styled(PageHeader)`
  margin: 0;
  margin-top: 28px;
  + div {
    width: initial !important;
  }
  @media (max-width: 767px) {
    margin: 0;
    padding: 0;
  }
`;
