import media from 'utils/mediaStyle';
import styled from 'styled-components';

export const FilterContainer = styled.div<any>`
  flex-grow: 1;
  width: 100%;
  display: flex;
  padding: 12px 0;
  background-color: #f9fafc;
  align-items: center;
  ${media.md`
    flex-direction: row;
    margin-bottom: 15px;
  `};
  ${({ castShadow }) =>
    castShadow
      ? `
    box-shadow: 0px 1px 7px 2px rgba(0, 0, 0, 0.3);
    width: 100vw;
    padding-left: 10pt;
    padding-right: 10pt;
    margin-left: -6pt;
    `
      : ``};

  ${({ castShadow }) =>
    castShadow
      ? media.lg`
      padding-left: 104pt;
      width: calc(100vw - 50pt);
      max-width: 954px;
      margin-left: 1pt;
    `
      : ``};
  @media (max-width: 767px) {
    gap: 16px;
  }
`;

export const FilterBtnContainer = styled.div<any>`
  padding-top: 10pt;
  padding-bottom: 10pt;
  ${media.md`
    padding-left: 10pt;
    padding-right: 10pt;
    padding-top: 0;
    padding-bottom: 0;
  `};
`;
