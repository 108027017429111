import translations from 'translations';
import { Category, QueryProductSearchArgs } from 'types/schema';
import messages from './messages';

export enum FilterableKeys {
  CATEGORY_NAME = 'categoryName',
  TAGS = 'tags',
  QUERY = 'query',
  WITH_EXCLUSIVE = 'withexclusive',
}

/**
 * Return a string map from categories or a string
 * @param category is a category type, search is a Tsearch type, defaultMessage is a default string return if filter fit no condition
 * @returns A string map from categories types
 */

const renderCategoryName = (
  category?: Category | null,
  search?: Partial<QueryProductSearchArgs>,
  defaultMessage?: string,
): string => {
  if (category) {
    return category.parent ? `${category.parent.nameLocal}/${category.nameLocal}` : category.nameLocal;
  }
  if (search && search.filter && search.filter.tags) {
    return translations(messages[`${search.filter.tags?.[0]}`]);
  }
  if (search && search.filter && search.filter.query) {
    return `${translations(messages.searchResult)} "${search.filter.query}"`;
  }
  return defaultMessage || translations(messages.mart);
};

export default renderCategoryName;
