import React from 'react';
import { FormattedMessage } from 'react-intl';
import translations from 'translations';
import { debounce } from 'lodash';
import { Affix, Input } from 'antd';
import messages from '../messages';
import utilsMessages from 'utils/messages';
import { Viewport } from 'components/ViewportProvider';
import Button from 'components/Button/Button';
import { FilterContainer, FilterBtnContainer } from './FilterContainer';
import { InputContainer } from './InputContainer';
import { SearchOutlined } from '@ant-design/icons';

export class Filter extends React.Component<any> {
  public state = {
    searchProduct: this.props.searchProduct,
    castShadow: false,
    selectedSuppliers: this.props.selectedSuppliers || [],
  };
  public componentWillReceiveProps(nextProps: any) {
    this.setState({
      searchProduct: nextProps.searchProduct,
      selectedSuppliers: nextProps.selectedSuppliers,
    });
  }
  public onSearchWithDelay = debounce((query) => {
    this.props.onSearch(query);
  }, 1600);
  public onChangeSearch = ({ target: { value } }, isDesktop = true) => {
    this.setState({
      ...this.state,
      searchProduct: value,
    });
    if (isDesktop) {
      this.onSearchWithDelay(value);
    }
  };
  public onSearchProductName = (query, event) => {
    const name = query || (event && event.target.value);
    this.props.onSearch(name);
  };
  public onChangeSuppliers = (value) => {
    this.setState({ selectedSuppliers: value });
  };
  public onSearchSuppliers = () => {
    const { selectedSuppliers = [] } = this.state;
    this.props.onChangeSupplier(selectedSuppliers);
  };
  public onDeselectSupplier = (value) => {
    const selectedSuppliers = this.state.selectedSuppliers.filter((s) => s !== value);
    this.props.onChangeSupplier(selectedSuppliers);
  };
  public renderFilters(isMobile) {
    const { onSearch } = this.props;
    const { searchProduct, castShadow = false } = this.state;
    return (
      <FilterContainer key="filter-favorite" castShadow={isMobile ? false : castShadow}>
        <InputContainer key="search-favorite-container">
          <Input
            key="search-favorite-item"
            placeholder={`${translations(utilsMessages.search)} ${translations(
              utilsMessages.in,
            ).toLowerCase()} ${translations(utilsMessages.favorite).toLowerCase()}`}
            onChange={(event) => {
              this.onChangeSearch(event, !isMobile);
            }}
            onBlur={(event) => {
              onSearch(event.target.value);
            }}
            value={searchProduct}
            size="large"
            suffix={<SearchOutlined style={{ fontSize: '16px', cursor: 'pointer', color: '#2F904F' }} />}
          />
        </InputContainer>
        {isMobile && (
          <FilterBtnContainer>
            <Button block type="primary" onClick={this.onSearchSuppliers} size="large">
              <FormattedMessage {...messages.apply} />
            </Button>
          </FilterBtnContainer>
        )}
      </FilterContainer>
    );
  }
  public render() {
    return (
      <Viewport.Consumer>
        {({ width }) => {
          const isMobile = width < 575;
          if (isMobile) {
            return this.renderFilters(isMobile);
          }
          return (
            <Affix offsetTop={48} onChange={(affixState) => this.setState({ castShadow: affixState })}>
              {this.renderFilters(isMobile)}
            </Affix>
          );
        }}
      </Viewport.Consumer>
    );
  }
}
