import styled from 'styled-components';
import media from 'utils/mediaStyle';

export const InputContainer = styled.div`
  width: 100%;
  ${media.md`
    max-width: 280px;
    margin-bottom: 0px;
  `};
  & > .ant-input-affix-wrapper {
    border-radius: 8px !important;
  }
`;
